<!--
--------------------------------------------------------------------------------
<copyright file="LaserOutputScrap.vue" company="Bystronic Laser AG">
 Copyright (C) Bystronic Laser AG 2021-2024
</copyright>
--------------------------------------------------------------------------------
-->

<template>
  <section>
    <details>
      <summary>{{ $t('widget_info_text.X-axis') }}</summary>
      <p class="text">{{ $t('widget_info_text.outp_scrap_xaxis_txt') }}</p>
      <p class="info list-item info-bkg">{{ $t('widget_info_text.info') }}:</p>
      <p class="text info-bkg">{{ $t('widget_info_text.outp_scrap_xaxis_info_txt') }}</p>
      <p class="tip list-item tip-bkg">{{ $t('widget_info_text.tip') }}:</p>
      <ul class="list tip-bkg">
        <li class="list-item2">{{ $t('widget_info_text.outp_scrap_xaxis_tip_txt1') }}</li>
        <li class="list-item2">{{ $t('widget_info_text.bars_tip_txt_mat') }}</li>
        <li class="list-item2">{{ $t('widget_info_text.tooltip_txt') }}</li>
      </ul>
    </details>

    <details>
      <summary>{{ $t('widget_info_text.Y-axis') }}</summary>
      <p class="text">{{ $t('widget_info_text.outp_scrap_yaxis_txt') }}</p>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.bars') }}</summary>
      <p class="text">{{ $t('widget_info_text.outp_scrap_bars_txt') }}</p>
      <ul class="list">
        <li class="list-item">
          <span class="demi-bold">{{ $t('widget_info_text.outp_scrap_bars_left') }}</span> :
          {{ $t('widget_info_text.outp_scrap_bars_left_txt') }}
        </li>
        <li class="list-item">
          <span class="demi-bold">{{ $t('widget_info_text.outp_scrap_bars_right') }}</span
          >:
          {{ $t('widget_info_text.outp_scrap_bars_right_text') }}
        </li>
      </ul>
      <p class="info list-item info-bkg">{{ $t('widget_info_text.info') }}:</p>
      <p class="text info-bkg">{{ $t('widget_info_text.bars_info_txt') }}</p>
      <p class="demi-bold text">{{ $t('widget_info_text.bars') }}</p>
      <ul class="list">
        <li class="list-item">
          <span class="demi-bold">{{ $t('widget_info_text.top_bar_segment') }}</span> :
          {{ $t('widget_info_text.scrap_weight_txt') }}.
        </li>
        <li class="list-item">
          <span class="demi-bold">{{ $t('widget_info_text.bottom_bar_segment') }}</span
          >: {{ $t('widget_info_text.output_weight') }}
        </li>
        <li class="list-item">
          <span class="demi-bold">{{ $t('widget_info_text.total_height_bar') }}</span
          >:
          {{ $t('widget_info_text.total_height_bar_txt') }}
        </li>
      </ul>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.icons') }}</summary>
      <time-filter />
      <shift-filter />
    </details>
  </section>
</template>

<script language="ts">
import { Component, Vue } from 'vue-property-decorator';
import TimeFilterInfo from '@/components/Charts/widgetInfo/TimeFilter.vue';
import ShiftFilterInfo from '@/components/Charts/widgetInfo/ShiftFilter.vue';

@Component({
  components: {
    'time-filter': TimeFilterInfo,
    'shift-filter': ShiftFilterInfo,
  },
})
export default class LaserOutputScrap extends Vue {}
</script>
